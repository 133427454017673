<template>
    <b-modal ref="appVersionModal" id="appVersionModal" no-close-on-backdrop no-close-on-esc>
        <template #modal-header>
            <div class="simple-modal__title h4 text-center border-bottom w-100 pt-4 pb-1">
                <span class="font-weight-bold m-0 h4">Une nouvelle version de l'application est disponible</span>
            </div>
        </template>
        <template v-slot:default>
            <b-container fluid>
                <b-row>
                    <b-col sm="12">Afin de profiter des dernières fonctionnalités et des derniers correctifs, nous vous
                        invitons à actualiser votre application pour profiter de la mise à jour.
                    </b-col>
                </b-row>
            </b-container>
        </template>
        <template v-slot:modal-footer>
            <button type="submit" class="btn btn-lg btn-success w-100 m-0 no-top-border-radius" @click="reload()">
                <span><i class="far fa-calendar-alt mr-2"></i>Actualiser</span>
            </button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: 'confirm-update-modal',
    data() {
        return {
            title: "",
            message: ""
        }
    },
    watch: {
        contentReady: function(val) {
            if(val) this.$refs['appVersionModal'].show();
        }
    },
    props: {
        contentReady: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        reload() {
            location.href = "/mise-a-jour"
        }
    }
}
</script>