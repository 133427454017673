<template>
  <b-modal class="simple-modal"
           ref="modal"
           hide-footer>
      <template #modal-header>
          <p class="simple-modal__title h4 text-center border-bottom w-100 pt-4 pb-1">{{ title }}</p>
          <b-button-close @click="() => { $refs.modal.hide() }"></b-button-close>
      </template>
      <p class="text-center mt-2">{{ message }}</p>
  </b-modal>
</template>

<script>
export default {
  name: 'simple-modal',
  props: {
    data: Object,
    refId: String
  },
  data () {
    return {
        title: "",
        message: ""
    }
  },
  methods: {
      modalAction(data) {
          this.title = data.title;
          this.message = data.message;

          this.$nextTick(() => {
              this.$refs.modal.show()
          })
      }
  }
}
</script>