module.exports = {
  contractDetail: 'Détails',
  contractDetailIcon: 'b-icon-chevron-right',
  logoImage: '/img/logo-merci.png',
  brandName: 'Merci +',
  address: '68 Bvd Sébastopol<br>75003 PARIS',
  phone: '01 86 76 10 24',
  tel: '+33186761024',
  ggMap: '/img/map-merci.jpg',
  contractDetailComponent: 'contract-detail-merci',
  states: {
      validated: {
          color: 'success',
          text: 'validée',
          presta: true
      },
      done: {
          color: 'success',
          text: 'effectuée',
          planning: true,
      },
      waiting: {
            // color: 'warning',
          color: 'primary',
          text: 'En attente',
          planning: true,
      },
      toValidate: {
          color: 'black',
          text: 'À valider',
          presta: true
      },
      verifying: {
          color: 'warning',
          text: 'En cours de validation',
          presta: true,
      },
      upcoming: {
          color: 'accent',
          text: 'à venir',
          planning: true,
      },
      cancelled: {
          color: 'gray',
          text: 'annulée facturée',
          planning: true,
          presta: true
      }
  },
}
