import Vue from 'vue'
import Vuex from 'vuex'
import { state } from './state'
import * as mutations from './mutation'
import plugins from './plugins'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  plugins
})
