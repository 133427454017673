<template>
  <div class="app-nav__container">
    <b-container class="h-100 px-0 d-flex justify-content-between">
      <div class="app-nav__logo">
        <b-img :src="$appVersion('logoImage')"></b-img>
      </div>
      <b-nav class="app-nav">
        <b-nav-item :style="`width:${100 / menu.length}%`" v-for="item in menu"
                    :to="item.path"
                    active-class="nav-link--active"
                    :key="`menu-${item.name}`"
                    exact>
            <component :is="item.icon"
                       class="nav-icon"></component>
            <span class="nav-text">{{ item.name }}</span>
        </b-nav-item>
      </b-nav>
<!--      <b-icon-bell-fill class="app-nav__bell display-2 text-accent"></b-icon-bell-fill>-->
    </b-container>
  </div>
</template>

<script>
import {
    BIconBellFill,
    BIconCalendar4Week,
    BIconChatSquareText,
    BIconColumnsGap,
    BIconFiles,
    BIconPerson
} from 'bootstrap-vue'

export default {
  components: { BIconColumnsGap, BIconCalendar4Week, BIconFiles, BIconChatSquareText, BIconPerson, BIconBellFill },
  name: 'app-nav',
  data () {
    return {
      menu: [
          // {
          //   path: '/',
          //   name: 'Accueil',
          //   icon: 'b-icon-columns-gap'
          // },
          {
              path: '/prestations',
              name: 'Prestations',
              icon: 'b-icon-calendar4-week'
          },
          {
              path: '/documents',
              name: 'Documents',
              icon: 'b-icon-files'
          },
          {
              path: '/contact',
              name: 'Contact',
              icon: 'b-icon-chat-square-text'
          },
          {
              path: '/compte',
              name: 'Compte',
              icon: 'b-icon-person'
          }
      ]
    }
  }
}
</script>