<template>
  <div id="app">
      <div v-if="offline"
           style="position: fixed; width: 100vw; height: 100vh; z-index: 999999999999999; background-color: rgba(0, 0, 0, 0.7)">
          <div style="background-color: white;" class="">
              <h3 style="background-color: rgba(100, 0, 0, 0.5); color: white;" class="text-center bg-danger p-2">
                  Attention
              </h3>
              <div class="p-3">
                  Aucune connexion internet, vous êtes en mode hors ligne, certaines fonctionnalités ont été
                  désactivées.
              </div>
          </div>
      </div>
      <transition :name="`slide-${direction}`">
          <router-view/>
      </transition>
      <app-nav v-if="!hideNav.includes($route.name)"/>
      <simple-modal ref="responseModal"></simple-modal>
      <confirm-update-modal :content-ready="newContentReady"></confirm-update-modal>
  </div>
</template>

<script>
import AppNav from '@/components/layout/AppNav'
import SimpleModal from '@/components/layout/elements/SimpleModal'
import ConfirmUpdateModal from '@/components/layout/elements/ConfirmUpdateModal'
import AlertHandler from '@/alertHandler'

import {routes} from './router'

export default {
    name: 'app',
    components: {AppNav, SimpleModal, ConfirmUpdateModal},
    data() {
        return {
            direction: 'left',
            routeList: routes.map(route => route.name),
            hideNav: ['login'],
            newContentReady: false,
            connectionError: false,
        }
    },
    computed: {
        offline() {
            return this.connectionError
        }
    },
    created() {
        addEventListener('content-ready', () => {
            console.log("new version available");
            this.newContentReady = true;
        })

        if ((!location.href.includes("localhost") && process.env.VUE_APP_ENV != 'TEST')) {
            setInterval(() => {
                let cancelTokenSource = axios.CancelToken.source();

                axios.get(`${process.env.VUE_APP_API_URL}api/online`, {
                    cancelToken: cancelTokenSource.token
                }).then(res => {
                    this.connectionError = !res.data;
                }).catch(() => {
                    this.connectionError = true;
                });

                setTimeout(() => {
                    cancelTokenSource.cancel();
                }, 5000)
            }, 10000)
        }
    },
    mounted() {
        AlertHandler.init(this.$refs['responseModal'], this.$rollbar);
        AlertHandler.add({
            code: 'loginError',
            message: 'Votre identifiant ou mot de passe est erroné.'
        });
        AlertHandler.add({
            code: 'createSent', title: 'Merci !',
            message: 'Un E-mail vous a été envoyé avec votre mot de passe provisoire. Une fois connecté(e), nous vous invitons à le modifier dans votre compte.'
        });
        AlertHandler.add({
            code: 'resetSent', title: 'Succès !',
            message: 'Un E-mail de réinitialisation de mot de passe vous a été envoyé.'
        })
        AlertHandler.add({
            code: 'createSentError',
            message: 'Un compte existe déjà avec cette adresse E-mail.'
        })
        AlertHandler.add({
            code: 'password-reset', title: 'Succès !',
            message: 'Mot de passe réinitialisé avec succès'
        })
        AlertHandler.add({
            code: 'password-change', title: 'Succès !',
            message: 'Mot de passe changé avec succès'
        })
        AlertHandler.add({
            code: 'messageSent', title: 'Merci !',
            message: 'Votre message a bien été envoyé, l\'agence se chargera de revenir vers vous dans les plus brefs délais.'
        })
        AlertHandler.add({
            code: 'reportSent', title: 'Merci !',
            message: 'Votre remarque a bien été envoyée. Nous la traiterons dès que possible.'
        })
        AlertHandler.add({
            code: 'instructionSent', title: 'Merci !',
            message: 'Votre consigne a bien été ajoutée à l\'intervention, votre intervenante sera informée dans les plus brefs délais.'
        })
        AlertHandler.add({
            code: 'cancelSent', title: 'Merci !',
            message: 'Votre demande a bien été prise en compte.'
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                if (!to || !from) return
                this.direction = this.routeList.indexOf(to.name) > this.routeList.indexOf(from.name) ? 'left' : 'right'
            }
        }
    },
    methods: {
    }
}
</script>