module.exports = {
    contractDetail: 'Voir',
    contractDetailIcon: 'b-icon-eye',
    logoImage: '/img/logo-family.png',
    brandName: 'Family Sphère',
    address: '2 Bvd Saint-Denis<br>75010 PARIS',
    phone: '06 65 79 86 47',
    tel: '+33665798647',
    ggMap: '/img/map-family.jpg',
    contractDetailComponent: 'contract-detail-family',
    states: {
        validated: {
            color: 'success',
            text: 'validée',
            presta: true
        },
        done: {
            color: 'success',
            text: 'effectuée',
            planning: true,
        },
        waiting: {
            color: 'warning',
            text: 'En attente',
            planning: true,
        },
        toValidate: {
            color: 'black',
            text: 'À valider',
            presta: true
        },
        verifying: {
            color: 'warning',
            text: 'En cours de validation',
            presta: true,
        },
        upcoming: {
            color: 'accent',
            text: 'à venir',
            planning: true,
        },
        cancelled: {
            color: 'gray',
            text: 'annulée facturée',
            planning: true,
            presta: true
        }
    },
}
