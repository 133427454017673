export const STORAGE_KEY = '5f3a7527bc20e'

let initialState = {}

if (localStorage.getItem(STORAGE_KEY)) {
    initialState = JSON.parse(localStorage.getItem(STORAGE_KEY))
} else {
    initialState = {
        auth: {
            isLoggedIn: false,
            accessToken: null,
            refreshToken: null
        },
        user: {
            email: null,
            idutilisateur: null,
            login: null,
            mobile: null,
            nom: null,
            prenom: null,
            usid: null,
            admin: false
        },
        // months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        // days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    }
}

initialState.months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
initialState.days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
initialState.prestationStates = {
    validated: {
      color: 'success',
      text: 'validée'
    },
    // toValidate: {
    //   color: 'black',
    //   text: 'à valider'
    // },
    verifying: {
      color: 'warning',
      text: 'vérification'
    },
    upcoming: {
      color: 'accent',
      text: 'à venir'
    },
    cancelled: {
      color: 'gray',
      text: 'annulée'
    }
  }

export const state = initialState

