import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router)

export const routes = [
    {
        path: '/login',
        name: 'login',
        component: function (resolve) {
            require(['./components/pages/authentication/Login.vue'], resolve)
        },
        props: true
    },
    {
        path: '/admin',
        name: 'admin',
        component: function (resolve) {
            require(['./components/pages/authentication/Admin.vue'], resolve)
        },
        props: true
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: function (resolve) {
            require(['./components/pages/authentication/PasswordReset.vue'], resolve)
        },
        props: true
    },
    {
        path: '/',
        name: 'prestations',
        component: function (resolve) {
            require(['./components/pages/prestations/Prestations.vue'], resolve)
        },
        beforeEnter: guardRoute
    },
    {
        path: '/prestations',
        name: 'prestations',
        component: function (resolve) {
            require(['./components/pages/prestations/Prestations.vue'], resolve)
        },
        beforeEnter: guardRoute
    },
    // {
    //     path: '/contrats',
    //     name: 'contrats',
    //     component: function (resolve) {
    //         require(['./components/pages/contrats/Contrats.vue'], resolve)
    //     },
    //     beforeEnter: guardRoute
    // },
    {
        path: '/documents',
        name: 'documents',
        component: function (resolve) {
            require(['./components/pages/contrats/Documents.vue'], resolve)
        },
        beforeEnter: guardRoute
    },
    {
        path: '/contrats/detail',
        name: 'detail-contrat',
        component: function (resolve) {
            require(['./components/pages/contrats/ContratDetail.vue'], resolve)
        },
        beforeEnter: guardRoute
    },
    {
        path: '/contrats/consignes',
        name: 'consignes-contrat',
        component: function (resolve) {
            require(['./components/pages/contrats/consignes/Consignes.vue'], resolve)
        },
        beforeEnter: guardRoute
    },
    {
        path: '/contact',
        name: 'contact',
        component: function (resolve) {
            require(['./components/pages/contact/Contact.vue'], resolve)
        },
        beforeEnter: guardRoute
    },
    {
        path: '/compte',
        name: 'compte',
        component: function (resolve) {
            require(['./components/pages/compte/Compte.vue'], resolve)
        },
        beforeEnter: guardRoute
    }
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

export default router;

function guardRoute(to, from, next) {
    const auth = store.state.auth
    if (!auth.isLoggedIn) {
        next({
            name: 'login'
        })
    } else {
        next()
    }
}
