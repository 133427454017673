import store from './store'

export default {
    options: {},
    baseUrl: process.env.VUE_APP_API_URL,
    activation: true,
    countRetry: 0,
    refreshingToken: false,
    install(Vue, options) {
        this.options = options
        window.axios.interceptors.response.use((response) => {
            return response
        }, (err) => {
            let retour = false
            if (this.activation) {
                if (!err?.response?.status) throw err;

                switch (err.response.status) {
                    case 401:
                        if (err.response.config.url.includes('oauth/token/refresh')) {
                            retour = this._refreshToken(err.response)
                        } else if (err.response.config.url.includes('oauth/token')) {
                            retour = false;
                        } else {
                            retour = this.logout()
                        }
                        break
                    default:
                        retour = false
                        break
                }
            }
            return retour
        })
    },

    login(username, password) {
        const params = {
            'grant_type': 'password',
            'username': username,
            'password': password,
            'client_secret': this.options.client_secret,
            'client_id': this.options.client_id
        }
        this.activation = true
        this.countRetry = 0
        return window.axios.post(this.baseUrl + 'oauth/token', params)
    },
    rememberMe(user, password) {
        store.commit('UPDATE_USER', user, password)
    },
    logout() {
        if (this.activation) {
            this.activation = false
        }
        store.commit('CLEAR_ALL_DATA')
        window.location.reload()
    },
    setAuthHeader(request) {
        request.headers['Authorization'] = 'Bearer ' + store.state.auth.accessToken
        return request
    },
    _retry(request) {
        return window.axios(this.setAuthHeader(request.config))
            .then((response) => {
                return response
            })
            .catch((response) => {
                return response
            })
    },

    _refreshToken(request) {
        const params = {
            'grant_type': 'refresh_token',
            'refresh_token': store.state.auth.refreshToken || 'null',
            'client_secret': this.options.client_secret,
            'client_id': this.options.client_id
        }
        if (request.config.headers.Authorization === 'Bearer ' + store.state.auth.accessToken && !this.refreshingToken) {
            this.refreshingToken = true
            return window.axios.post(this.baseUrl + '/oauth/token/refresh', params)
                .then((result) => {
                    if (this.activation) {
                        if (result !== undefined) {
                            if (result.status === 200) {
                                this._storeToken(result)
                                this.refreshingToken = false
                                this.countRetry = 0
                                return this._retry(request)
                            } else {
                                return this.logout()
                            }
                        }
                    }
                })
                .catch(() => {
                    this.logout()
                })
        } else if (this.activation && this.countRetry === 0) {
            this.countRetry += 1
            return this._retry(request)
        } else {
            return this.logout()
        }
    },
    _storeToken(response) {
        const auth = store.state.auth
        auth.isLoggedIn = true
        auth.accessToken = response.data.access_token
        auth.refreshToken = response.data.refresh_token
        store.commit('UPDATE_AUTH', auth)

    },
    _isInvalidToken(response) {
        const status = response.status
        const error = response.data.error
        return (status === 401 && error === 'Unauthenticated.')
    },

}
