import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import auth from './auth'
import {BootstrapVue, LayoutPlugin} from 'bootstrap-vue'
import appVersionManager from './plugins/app-version-manager'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Rollbar from 'rollbar'

import {version} from '../package.json'

const marque = location.host.includes('family') ? 'family' : 'merci';
const appVersion = require(`@/app-versions/${marque}.js`)

Vue.use(BootstrapVue)
Vue.use(LayoutPlugin)
Vue.use(appVersionManager, appVersion)

require(`@/app-versions/merci.scss`) // pré-chargement pour ne pas avoir de page sans style
import(`@/app-versions/${marque}.scss`)

// MOMENT
const moment = require('moment')
require('moment/locale/fr')
Vue.use(require('vue-moment'), {
    moment
})

// AXIOS
if (!window.axios) {
    window.axios = require('axios')
}

// Initialisation de l'objet Auth
Vue.use(auth, {'client_secret': 'NHcSFH7Qs9CLx7JY2Mau2kFGB2calIjOcvGF2UC4', 'client_id': 2})
Vue.prototype.$auth = auth

Vue.config.productionTip = false

Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    checkIgnore: (isUncaught, args, payload) => {
        return payload.body.telemetry.find(i => [401, 419].includes(i.body?.status_code));
    },
    ignoredMessages: [
        /Loading.*chunk/,
        /Network.*error/
    ]
});

Vue.prototype.$rollbar.configure({
    enabled: !location.href.includes("localhost") && process.env.VUE_APP_ENV != 'TEST'
});

Vue.config.errorHandler = (err, vm) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
};

Vue.prototype.$rollbar._error = Vue.prototype.$rollbar.error;
Vue.prototype.$rollbar.error = (message, error = null) => {
    const user = store.state.user ?? {};

    if (user.admin) return;

    console.error(message, error ?? "");

    Vue.prototype.$rollbar.configure({
        payload: {
            environment: process.env.VUE_APP_ENV,
            id: user.idutilisateur,
            message: message,
            fingerprint: message,
            client: {
                javascript: {
                    code_version: version
                }
            }
        }
    });

    Vue.prototype.$rollbar._error(message);
}

document.title = 'Espace client';

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
