export default {
    component: null,
    rollbar: null,
    alerts: {},
    init(component, rollbar) {
        this.component = component;
        this.rollbar = rollbar;
    },
    add({code = 0, title = '', message = ''}) {
        this.alerts[code] = {
            title,
            message
        }
    },
    show({
             code = 0,
             title = 'Oups !',
             message = 'Une erreur est survenue, veuillez réessayer ultérieurement.',
             error = null
         }) {
        const e = this.alerts[code] ?? {
            title: code ? `Erreur ${code}` : title,
            message
        };
        if (error) this.rollbar.error(e.title, error);
        this.component.modalAction(e);
    }
}


