export const UPDATE_AUTH = (state, auth) => {
    state.auth = auth
}

export const UPDATE_USER = (state, user) => {
    let data = {
        email: user.email,
        idutilisateur: user.idutilisateur,
        login: user.login,
        mobile: user.mobile,
        nom: user.nom,
        prenom: user.prenom,
        usid: user.usid,
        tracked: user.tracked,
        admin: user.admin
    }
    state.user = data
}

export const UPDATE_TOKEN = (state, token) => {
    state.auth.accessToken = token
}

export const clearToken = (state) => {
    state.auth.accessToken = ''
}

export const CLEAR_ALL_DATA = (state) => {
    state.auth = {
        isLoggedIn: false,
        accessToken: null,
        refreshToken: null,
        expires_in: null,
        expires_date_heure: null
    }
    state.user = {
        id: null,
        name: '',
        email: '',
        options: [],
        login: '',
        props: [],
        modules: []
    }
}
